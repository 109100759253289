.wwwebdesign_section_container{
	max-width: 1180px;
	padding: 0 20px;
	width: 100%;
	margin: 0 auto;
}
.wwwebdesign_section_title{
	margin: 0 0 1.4em 0;
}
.d_flex{
	display: flex;
}
.flex_wrap{
	flex-wrap:wrap;
}
.text_left{
	text-align: left;
}
.justify_content_end{
	justify-content:flex-end;
}
.justify_content_center{
	justify-content:center;
}
.align_items_center{
	align-items:center;
}
.wwwebdesign_section{
	padding-bottom: 110px;
	@media screen and (max-width: 768px) {
		padding-bottom: 30px;
	}
}
.wwwebdesign_item_list{
	position: relative;
	&:after{
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform:translate(-50%,-50%);
		background: #f6fbff;
		width: 795px;
		height: 795px;
		border-radius: 50%;
		z-index: -1;
		margin-top: -20px;
	}
}
.wwwebdesign_item{
	margin: 0 -20px;
	padding-bottom: 65px;
	&._scr-item{
		transform: translate(0,10%);
		transition: all .8s ease 0s;
		opacity: 0;
		&._active{
			transform: translate(0,0);
			opacity: 1;
			&:nth-child(1){
				transition: all .3s ease .1s;
			}
			&:nth-child(2){
				transition: all .3s ease .2s;
			}
			&:nth-child(3){
				transition: all .3s ease .3s;
			}
		}
	}
	.item_number,.item_txt,.item_img{
		padding: 0 20px;
	}
	.item_number{
		min-width: 160px;
		text-align: center;
	}
	.item_img{
		// max-width: 165px;
		// flex:0 0 165px;
		align-self:center;
		picture{
			display: block;
			// width: 100%;
		}
		img{
			width: 100%;
		}
	}
	.item_number_cont{
		font-size: 100px;
		font-weight: bold;
		line-height: 80%;
		position: relative;
		padding-bottom: 26px;
		&:after{
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			background: #369cf6;
			border-radius: 6px;
			width: 100%;
			height: 13px;
		}
	}
	.item_txt{
		max-width: 52%;
		flex:0 0 52%;
		h4{
			font-size: 24px;
			line-height: 26px;
			font-weight: bold;
			padding-bottom: 24px;
		}
		p{
			font-size: 16px;
			line-height: 29px;
		}
	}
	@media screen and (max-width: 991px) {
		.item_number_cont{
			font-size: 70px;
		}
	}
	@media screen and (max-width: 768px) {
		flex-wrap:wrap;
		justify-content:center;
		text-align: center;
		.item_txt{
			max-width: 100%;
			flex:0 0 100%;
		}
		.item_img{
			display: none;
		}
		.item_number{
			padding-bottom: 30px;
		}
	}
}
.webdesign_section_title{
	font-size: 70px;
	margin: 0 0 1.4em 0;
	&.fz_60{
		@media screen and (min-width: 1441px) {
			font-size: 60px;
		}
	}
	@media screen and (max-width: 1440px) {
		font-size: calc(30px + 70 * ((100vw - 320px)/ 1120));
	}
	ul{
		display: block;
		color: #369cf6;
	}
}
.webdesign_section{
	@media screen and (max-width: 768px) {
		padding-top: 50px;
	}
}
.webdesign_list{
	display: flex;
	flex-wrap:wrap;
	margin: 0 -20px;
	@media screen and (max-width: 768px) {
		margin: 0 -10px;
	}
	.webdesign_item{
		flex:0 0 50%;
		max-width: 50%;
		padding: 0 20px;
		padding-bottom: 100px;
		&._scr-item{
			transform: translate(0,10%);
			transition: all .8s ease 0s;
			opacity: 0;
			&._active{
				transform: translate(0,0);
				opacity: 1;
				&:nth-child(1){
					transition: all .3s ease .1s;
				}
				&:nth-child(2){
					transition: all .3s ease .2s;
				}
				&:nth-child(3){
					transition: all .3s ease .3s;
				}
				&:nth-child(4){
					transition: all .3s ease .4s;
				}
			}
		}
		@media screen and (max-width: 991px) {
			max-width: 100%;
			flex: 0 0 100%;
		}
		@media screen and (max-width: 768px) {
			padding-bottom: 50px;
		}
	}
	.webdesign_item_row{
		display: flex;
		margin: 0 -20px;
		@media screen and (max-width: 768px) {
			margin: 0 -10px;
		}
	}
	.webdesign_item_img,.webdesign_item_text{
		padding-left: 20px;
		padding-right: 20px;
		@media screen and (max-width: 768px) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	.webdesign_item_img{
		max-width: 128px;
		flex:0 0 128px;
		@media screen and (max-width: 768px) {
			max-width: 70px;
			flex:0 0 70px;
		}
	}
	.webdesign_item_text{
		h4{
			font-size: 20px;
			line-height: 22px;
			color: #000;
			text-transform: uppercase;
			font-weight: 900;
			padding-bottom: 17px;
		}
		p{
			font-size: 16px;
			line-height: 29px;
			// font-size: calc(14px + 3 * ((100vw - 320px)/ 1120));
			a{
				color: #000;
				text-decoration: underline;
				&:hover{
					text-decoration: none;
				}
			}
		}
	}
}
.kundenstimmen__item_text{
	color: rgba(#3e3e3e,.5);
	font-size: 20px;
	line-height: 1;
}
.webdesign_tools_section{
	padding-bottom: 108px;
}
.webdesign_tools_item{
	display: flex;
	flex-wrap:wrap;
	@media screen and (max-width: 991px) {
		// justify-content:center;
		display: block;
		text-align: center;
	}
	&._scr-item{
		transform: translate(0,10%);
		transition: all .8s ease 0s;
		opacity: 0;
		&._active{
			transform: translate(0,0);
			opacity: 1;
			&:nth-child(1){
				transition: all .3s ease .1s;
			}
			&:nth-child(2){
				transition: all .3s ease .2s;
			}
			&:nth-child(3){
				transition: all .3s ease .3s;
			}
		}
	}
	.webdesign_tools_item_text,.webdesign_tools_item_img{
		transform: translate(0,10%);
		transition: all .8s ease 0s;
		opacity: 0;
		&._active{
			transform: translate(0,0);
			opacity: 1;
		}
	}
	.webdesign_tools_item_img{
		// box-shadow: 16px 16px 50px 0px rgba(#000,.1);
		box-shadow: 0 0 50px 0px rgba(#000,.1);
		max-width: 47%;
		flex:0 0 47%;
		img{
			max-width: 100%;
			display: block;
			height: auto;
		}
		order:-1;
		@media screen and (max-width: 991px) {
			display: table;
			margin: 0 auto;
			max-width: 100%;
		}
	}
	.webdesign_tools_item_text{
		min-width: 0;
		max-width: 100%;
		flex-grow: 1;
		flex-basis: 0;
		padding-left: 77px;
		@media screen and (max-width: 991px) {
			padding-left: 0;
			flex:0 0 100%;
			max-width: 100%;
			padding-bottom: 30px;
		}
		h4,h3{
			font-size: 20px;
			line-height: 22px;
			font-weight: 900;
			padding-bottom: 18px;
		}
		p{
			font-size: 16px;
			line-height: 29px;
			padding-bottom: 30px;
			a{
				color: #000;
				text-decoration: underline;
			}
			&:last-of-type{
				padding-bottom: 0;
			}
		}
	}
}
.ticker_list{
	display: inline-block;
	text-align: left;
}
.webdesign_section_title.d-flex{
	display: flex;
	flex-wrap:wrap;
	align-content:center;
	justify-content:center;
	position: relative;
}
.ticker_list{
	max-height: 70px;
	overflow: hidden;
	padding-top: 3px;
	margin-top: -3px;
}
.ticker_list_item{
	height: 70px;
	// will-change: auto;
	// margin-bottom: 7px;
	// will-change: transform;
}
.ticker_list_item.sliding-now {
	animation: up .6s linear 1 forwards;
	animation-delay: .2s;
	// will-change: transform;
}

@keyframes up {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-70px);
  }
}
.slide_title{
	line-height: 1;
	@media screen and (max-width: 576px) {
		padding-left: 10px;
		text-align: left !important;
		justify-content: flex-start !important;
		ul{
			text-align: left !important;
		}
		&.sm_center{
			text-align: center !important;
			justify-content: center !important;
			ul{
				text-align: center !important;
			}
		}
	}
}
@media screen and (max-width: 1440px) {
	
	// $hight: calc(30px + 70 * ((100vw - 320px) / 1120));
	$hight: 35px;
	.slide_title{
		font-size: 35px;
		.ticker_list{
			max-height: $hight;
		}
		.ticker_list_item{
			height: $hight;
		}
	}
	@keyframes up {
	  from {
	    transform: translateY(0);
	  }
	  to {
	    transform: translateY(- $hight);
	  }
	}
}
.slide_title{
	@media screen and (max-width: 740px) {
		ul{
			max-width: 100%;
			flex:0 0 100%;
			text-align: center;
		}
	}
}
.kundenstimmen__body{
	margin-bottom: 60px;
}
// 18/01/2021
@media screen and (min-width: 1441px) {
	.m_cstm_title{
		margin-bottom: calc(1.4em * 2);
	}
	.kundenstimmen__body,.kundenstimmen__logos{
		margin-bottom: 120px;
	}
}
// /10/05/2021
.center_1222px{
	max-width: 1222px;
	margin: 0 auto;
}
.row_15{
	margin-left: -15px;
	margin-right: -15px;
	.col_12,.col_md_6{
		padding-left: 15px;
		padding-right: 15px;
	}
}
.col_md_6{
	max-width: 50%;
	flex:0 0 50%;
	@media screen and (max-width: 740px) {
		max-width: 100%;
		flex:0 0 100%;
	}
}
.header_page_txt{
	padding-top: 120px;
	padding-bottom: 160px;
	@media screen and (max-width: 991px)  {
		padding-top: 50px;
	}
	p{
		font-size: 18px;
		line-height: 34px;
		padding-bottom: 15px;
		@media screen and (max-width: 991px) {
			font-size: 16px;
			line-height: 32px;
		}
	}
	@media screen and (max-width: 740px) {
		p{
			text-align: center;
		}
	}
}

.topic_section{
	background: #181e23;
	.hero_img{
		
		display: block;
		position: relative;
		top: -160px;
		margin-bottom: -160px;
		img{
			box-shadow: 0 0 70px 0px rgba(#000,.2);
			display: block;
			max-width: 100%;
			height: auto;
			
			// box-shadow: 0 0 70px 0px red;
		}
	}
	.sub_text{
		text-align: center;
		padding: 100px 20px 95px;
		h3{
			font-size: 60px;
			line-height: 61px;
			color: #fff;
			margin: 0;
			font-weight: 900;
			padding-bottom: 35px;
		}
		p{
			font-size: 18px;
			line-height: 20px;
			color: #54697b;
			letter-spacing: 7px;
		}
		@media screen and (max-width: 991px) {
			padding: 60px 20px;
			h3{
				font-size: 40px;
				line-height: 42px;
			}
			p{
				font-size: 16px;
				line-height: 18px;
			}
		}
	}
}
.topic_one_list{
	margin: 0;
	padding: 0;
	list-style: none;
	color: #fff;
	li{
		padding-right: 20px;
		padding-bottom: 5px;
		@media screen and (max-width: 768px) {
			max-width: 100%;
			flex:0 0 100%;
			padding-bottom: 15px;
			justify-content:center;
			padding-right: 0;
		}
	}
	a{
		color: #fff;
	}
	span{
		font-size: 18px;
		line-height: 20px;
		color: #fff;
	}
}
.text_center{
	text-align: center;
}
.topic_one{
	margin-bottom: 30px;
	background: #1b242c;
	padding: 93px 10px 75px 85px;
	box-shadow: 0 0 70px 0px rgba(#000,.2);
	h3{
		font-size: 40px;
		line-height: 42px;
		color: #f9f9f9;
		font-weight: 900;
		padding-bottom: 25px;
		@media screen and (max-width: 768px) {
			text-align: center;
		}
		span{
			color: #1d8df3;
		}
	}
	@media screen and (max-width: 991px) {
		margin-bottom: 15px;
		padding: 30px 20px;
		@media screen and (max-width: 768px){
			padding: 30px 10px;
		}
		h3{
			font-size: 35px;
			line-height: 37px;
			@media screen and (max-width: 768px) {
				font-size: 30px;
				line-height: 32px;
			}
		}
	}
}
.meta_section{
	max-width: 1286px;
	margin: 0 auto;
	padding: 131px 20px;
	ul{
		margin: 0;
		padding:100px 0 0 0;
		list-style: none;
		li{
			padding: 0 10px 23px;
			@media screen and (max-width: 550px) {
				max-width: 100%;
				flex:0 0 100%;
				span{
					display: inline-block !important;
				}
			}
			span{
				background: #1b242c;
				border: 1px solid #2c3b49;
				font-size: 16px;
				color: #7692aa;
				border-radius: 20px;
				padding: 12px 30px;
				display: block;
			}
		}
		@media screen and (max-width: 991px) {
			padding-top: 50px;
			li{
				padding: 0 5px 15px;
				span{
					font-size: 15px;
					padding: 10px 20px;
				}
			}
		}
	}
	h2{
		font-size: 40px;
		line-height: 42px;
		color: #fff;
		font-weight: 400;
		padding-bottom: 47px;
	}
	p{
		font-size: 18px;
		line-height: 20px;
		color: #54697b;
		letter-spacing: 7px;
	}
	@media screen and (max-width: 991px) {
		padding: 50px 20px;
		h2{
			font-size: 35px;
			line-height: 37px;
			padding-bottom: 30px;
		}
		p{
			font-size: 16px;
			line-height: 18px;
		}
	}
}
.justify_content_between{
	justify-content: space-between;
}
.section_grv_centered{
	max-width: 1342px;
	margin-left: auto;
	margin-right: auto;
	p{
		font-size: 18px;
		line-height: 34px;
		@media screen and (max-width: 991px) {
			font-size: 16px;
			line-height: 32px;
		}
	}
	.grv_txt_header{
		display: flex;
		align-items:center;
		font-size: 50px;
		line-height: 52px;
		color: #000;
		font-weight: 900;
		padding-bottom: 30px;
		i{
			max-width: 38px;
			flex:0 0 38px;
			img{
				display: block;
				height: auto;
				max-width: 100%;
			}
		}
		span{
			padding-left: 16px;
		}
		@media screen and (max-width: 991px) {
			font-size: 40px;
			line-height: 42px;
			padding-bottom: 15px;
			justify-content:center;
		}
	}
}
.section_grv{
	padding-left: 20px;
	padding-right: 20px;
}
.section_grv_columns{
	padding-top: 128px;
	margin-left: -15px;
	margin-right: -15px;
	.col_md_4{
		padding-bottom: 15px;
		max-width: 410px;
		flex-grow: 1;
		flex-basis: 0;
		width: 100%;
		// padding-left: 55px;
		// padding-right: 55px;
		padding-left: 15px;
		padding-right: 15px;
		@media screen and (max-width: 991px) {
			max-width: 100%;
			flex:0 0 100%;
			padding-bottom: 30px;
			text-align: center;
		}
	}
	@media screen and (max-width: 991px) {
		padding-top: 50px;
	}
}
.verbessern_block{
	padding-top: 135px;
	padding-bottom: 145px;
	@media screen and (max-width: 991px) {
		display: block;
		text-align: center;
		padding-bottom: 30px;
		padding-top: 40px;
		@media screen and (max-width: 768px) {
			padding-bottom: 0;
		}
	}
	.verbessern_txt_part{
		padding-right: 77px;
		flex-grow: 1;
		flex-basis: 0;
		width: 100%;
		max-width: 100%;
		@media screen and (max-width: 991px) {
			max-width: 100%;
			flex:0 0 100%;
			padding-bottom: 30px;
			padding-right: 0;
		}
		h2{
			color: #369cf6;
			font-size: 40px;
			line-height: 42px;
			font-weight: 900;
			padding-bottom: 34px;
			@media screen and (max-width: 991px) {
				font-size: 35px;
				line-height: 37px;
				padding-bottom: 15px;
			}
		}
	}
	.verbessern_img_part{
		box-shadow: 0 0 50px 0px rgba(0,0,0,0.1);
		max-width: 47%;
		flex: 0 0 47%;
		@media screen and (max-width: 991px) {
			display: table;
			margin: 0 auto;
			max-width: 100%;
		}
		img{
			display: block;
			height: auto;
			max-width: 100%;
		}
	}
}
.call-header__icon{
	img{
		width: 29px;
	}
}
// 24.09.2021
.referenzen{
	&.no_gutters{
		margin-left: 0 !important;
		margin-right: 0 !important;
		.referenzen__column{
			padding-left: 0 !important;
			padding-right: 0 !important;
			margin-bottom: 0 !important;
		}
	}
}
.vergrossern_text{
	max-width: 331px;
	width: 100%;
	display: block;
	height: auto;
	margin: 0 auto 60px;
	@media screen and (max-width: 991px) {
		margin-bottom: 30px;
	}
}
.homepage__header_with_img{
	h1{
		margin-bottom: 70px;
		@media screen and (max-width: 991px) {
			margin-bottom: 30px;
		}
	}
}
.videoblock{
	@media screen and (min-width: 992px) {
		padding-top: 70px;
		padding-bottom: 80px;
	}
	.item-videoblock__poster{
		box-shadow: 30px 30px 110px 0px rgba(#000,.4);
	}
	.videoblock__bg{
		background: #12202b;
		picture{
			display: none !important;
		}
	}
}
.text_primary{
	color: #369cf6;
}
.description_text{
	padding-top: 35px;
	p{
		font-size: 18px;
		line-height: 20px;
	}
}
.mb_0{
	margin-bottom: 0 !important;
}
.world_list{
	padding-top: 123px;
	margin-left: -15px;
	margin-right: -15px;
	.world_item{
		max-width: 25%;
		flex:0 0 25%;
		padding-left: 15px;
		padding-right: 15px;
		padding-bottom: 30px;
		.cstm__link{
			margin-top: 0;
		}
		.world_item_cnt{
			background: #fff;
			box-shadow: 0 0 50px 0px rgba(#000,.05);
			border-radius: 10px;
			transition: all 0.3s ease;
			p,a{
				color: #191e22;
				transition: all 0.3s ease;
			}
			p{
				font-size: 16px;
				line-height: 25px;
				margin-bottom: 12px;
			}
			a{
				display: inline-block;
				font-size: 14px;
				line-height: 16px;
			}
			&:hover{
				box-shadow: 0 0 50px 0px rgba(#369cf6,.15);
				a{
					color: #369cf6;
				}
			}
		}
		.img_prev{
			max-width: 112px;
			flex:0 0 112px;
		}
		.txt_prt{
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
			padding: 17px 5px 5px 29px;
			padding: 17px 5px 5px 24px;
		}
	}
	@media screen and (max-width: 1440px) {
		padding-top: 80px;
		.world_item{
			max-width: 33.33%;
			flex:0 0 33.33%;
			
			@media screen and (max-width: 1300px) {
				// padding-top: 100px;
				p{
					font-size: 14px !important;
					line-height: 18px !important;
				}
				@media screen and (max-width: 986px) {
					max-width: 50%;
					flex:0 0 50%;
					
					@media screen and (max-width: 650px) {
						max-width: 100%;
						flex:0 0 100%;
					}
				}
			}
		}
		@media screen and (max-width: 986px) {
			padding-top: 50px;
		}
	}
}
.world_item_cnt{
	@media screen and (max-width: 986px) {
		align-items:center;
		.txt_prt{
			padding-top: 5px !important;
		}
	}
}
.cstm__link{
	background:none !important;
	box-shadow: none !important;
	span{
		color: #333 !important;
		font-size: 18px !important;
		line-height: 50px !important;
	}
	&:hover{
		span{
			color: #38a8bd !important;
		}
	}
}
.world_section{
	padding-bottom: 140px;
	@media screen and (max-width: 986px) {
		padding-bottom: 70px;
	}
}
.referenzen-homepage__row.no_gutters{
	@media screen and (max-width: 768px) {
		margin-bottom: 20px;
	}
}
.align_content_center{
	align-content:center;
}
.decription_gal{
	align-items:center;
	justify-content:center;
	padding-bottom: 64px;
	font-size: 23px;
	line-height: 1;
	span{
		// font-size: 23px;
		// line-height: 26px;
		padding-right: 10px;
	}
	ul{
		color: #127fe0;
		// font-size: 23px;
		// line-height: 26px;
		max-height: 26px;
		// background: red;
		overflow: hidden;
		display: block;
		margin: 4px 0 0 0 !important;
		padding: 0 !important;
		.ticker_list_item{
			height: 26px;
		}
		@media screen and (max-width: 740px) {
			text-align: center;
		}
	}
}
.homepage__header_with_decription{
	.referenzen-homepage__title{
		margin-bottom: 35px;
	}
}
.web_block{
	padding-top: 80px;
}


.shadow_long_img{
	box-shadow: 30px 30px 110px 0px rgba(#000,.2) !important;
	// box-shadow: 30px 30px 110px 0px rgba(red,.2) !important;
}
.img_cstm_gal{
	margin: 0;
	padding: 0;
	list-style: none;
	position: relative;
	li{
		+ li{
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
.posts_list{
	.webdesign_tools_item{
		.webdesign_tools_item_text{
			// background: red;
			padding-right: 77px;
			@media screen and (max-width: 991px) {
				padding-left: 0;
				padding-right: 0;
			}
		}
		&.webdesign_tools_item_inverse{
			.webdesign_tools_item_img {
				order: 0;
			}
			.webdesign_tools_item_text {
				padding-left: 30px;
				padding-right: 144px;
			}
			@media screen and (max-width: 991px) {
				.webdesign_tools_item_text{
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}
	.webdesign_tools_item{
		+ .webdesign_tools_item{
			padding-top: 100px;
			@media screen and (max-width: 991px) {
				padding-top: 60px;
			}
		}
	}
	padding-bottom: 234px;
	@media screen and (max-width: 991px)  {
		padding-bottom: 40px;
	}
}
.popup_block .btn{
	box-shadow: 0 8px 24px rgba(53,157,245,.49);
	// &:hover{
	// 	opacity: .9;
	// }
}
.title{
	line-height: 100%;
}